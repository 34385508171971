import api from "@api";
import router from "@/router";
import store from "@/store";

export default {
    state: {
        packagesList: [],
        packageDto: {
            id: "",
            name: "",
            description: "",
            price: 0,
            type: 0,
            startDate: new Date(),
            endDate: new Date(),
            isHidden: false,
            subjectFaculties: [],
            selectedSubjectFaculties: [],
            packageLimit: 0
        },
        tabsFaculty: [],
        filterDto: {
            year: 0,
            semester: "",
            faculity: ""
        }
    },
    getters: {
        packagesAvaliableList(state) {
            return state.packagesList.filter(
                pack =>
                    new Date(pack.endDate) >= new Date() &&
                    new Date(pack.startDate) <= new Date() &&
                    !pack.isHidden
            );
        }
    },
    mutations: {
        Get_All_Packages(state, payload) {
            state.packagesList = payload;
        },
        Reset_Package_Dto(state) {
            Object.assign(state.packageDto, {
                id: "",
                name: "",
                description: "",
                price: 0,
                type: 0,
                startDate: new Date(),
                endDate: new Date(),
                isHidden: false,
                subjectFaculties: [],
                selectedSubjectFaculties: [],
                packageLimit: 0
            });
        },
        Package_Details(state, data) {
            Object.assign(state.filterDto, {
                year: data.filter.year,
                semester: data.filter.semesteId,
                faculity: data.filter.facultyId
            });
            Object.assign(state.packageDto, {
                id: data.id,
                name: data.name,
                description: data.description,
                price: data.price,
                type: data.type,
                startDate: data.startDate,
                endDate: data.endDate,
                isHidden: data.isHidden,
                subjectFaculties: [],
                selectedSubjectFaculties: [],
                packageLimit: data.packageLimit
            });
            data.subjectFaculties.forEach(subFac => {
                store.state.subjects.subjectsList.forEach(sub => {
                    let item = sub.subjectFaculties.find(
                        item => item.id == subFac.id
                    );
                    if (item) {
                        item.price = subFac.price;
                        state.packageDto.price += subFac.price;
                    }
                });
                state.packageDto.selectedSubjectFaculties.push(subFac.id);
            });
        },
        DELETE_PACKAGES(state, ids) {
            state.packagesList = state.packagesList.filter(
                el => !ids.includes(el.id)
            );
        },
        DELETE_PACKAGE(state, id) {
            state.packagesList = state.packagesList.filter(el => el.id != id);
        }
    },
    actions: {
        getAllPackages({ commit }, IncludeAutoGenPackages) {
            api.get(`Package/GetAll?IncludeAutoGenPackages=${IncludeAutoGenPackages}`, ({ data }) => {
                commit("Get_All_Packages", data);
            });
        },
        packageDetails({ commit }, payload) {
            api.get("Package/Details?id=" + payload, ({ data }) => {
                commit("Package_Details", data);
            });
        },
        addPackage({ commit }, payload) {
            api.post(
                "Package/Add",
                payload,
                () => {
                    commit("Reset_Package_Dto");
                    router.push("/packages");
                },
                {
                    success: "تمت عملية الإضافة بنجاح",
                    error: "فشلت عملية الإضافة"
                }
            );
        },
        setPackage({ commit }, payload) {
            api.put(
                "Package/Update",
                payload,
                () => {
                    commit("Reset_Package_Dto");
                    router.push("/packages");
                },
                {
                    success: "تمت عملية التعديل بنجاح",
                    error: "فشلت عملية التعديل"
                }
            );
        },
        deletePackages({ commit }, payload) {
            api.delete(
                "Package/RemovePackages",
                () => {
                    commit("DELETE_PACKAGES", payload);
                },
                {
                    success: "تم حذف الحزم بنجاح ",
                    confirm: "هل تريد بالتأكيد حذف الحزم المحددة ؟",
                    error: "فشل الحذف .. يرجى اعادة المحاولة "
                },
                payload
            );
        },
        deletePackage({ commit }, payload) {
            api.delete(
                `Package/RemovePackage?id=${payload}`,
                () => {
                    commit("DELETE_PACKAGE", payload);
                    router.push("/packages");
                },
                {
                    success: "تم حذف الحزم بنجاح ",
                    confirm: "هل تريد بالتأكيد حذف الحزمة  ؟",
                    error: "فشل الحذف .. يرجى اعادة المحاولة "
                }
            );
        }
    }
};
