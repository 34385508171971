import axiosIns from "@axios";
import { toast } from "@/libs/vue-toast.js";
import Swal from "sweetalert2";
import { serialize } from "object-to-formdata";

export default {
    post: (
        path,
        body,
        callback = () => {},
        message = { success: "success", error: "error" },
        onError = () => {},
        config = {}
    ) => {
        axiosIns
            .post(path, body, config)
            .then(data => {
                if (data.status == 200 && message !== false) {
                    toast.open({
                        message: message.success,
                        type: "success",
                        duration: 2000,
                        dismissible: true
                    });
                }
                callback(data);
            })
            .catch(err => {
                onError(err);
                toast.open({
                    message: err.response.data,
                    type: "error",
                    duration: 2000,
                    dismissible: true
                });
            });
    },

    put: (
        path,
        body,
        callback = () => {},
        message = { success: "success", error: "error" },
        onError = () => {},
        config = {}
    ) => {
        axiosIns
            .put(path, body, config)
            .then(data => {
                if (data.status == 200 && message != false) {
                    toast.open({
                        message: message.success,
                        type: "success",
                        duration: 2000,
                        dismissible: true
                    });
                }
                callback(data);
            })
            .catch(err => {
                onError(err);
                console.log(err.message);
                toast.open({
                    message: err.response.data,
                    type: "error",
                    duration: 2000,
                    dismissible: true
                });
            });
    },
    get: (path, callback = () => {}, onError = () => {}, config = {}) => {
        axiosIns
            .get(path, config)
            .then(data => {
                callback(data);
            })
            .catch(err => {
                onError(err);
                console.log("catch");
            });
    },
    delete: (
        path,
        callback = () => {},
        message = {
            confirm: "you will delete this item",
            success: "success",
            error: "error"
        },
        body = {},
        onError = () => {},
        header = {}
    ) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: true
        });
        swalWithBootstrapButtons
            .fire({
                title: "تنبيه!",
                text: message.confirm,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "نعم",
                cancelButtonText: "إلغاء",
                reverseButtons: false
            })
            .then(result => {
                if (result.value) {
                    axiosIns
                        .delete(path, { header, data: body })
                        .then(data => {
                            if (data.status == 200 && message != false) {
                                toast.open({
                                    message: message.success,
                                    type: "success",
                                    duration: 2000,
                                    dismissible: true
                                });
                            }
                            callback(data);
                        })
                        .catch(err => {
                            toast.open({
                                message: err.response.data,
                                type: "error",
                                duration: 2000,
                                dismissible: true
                            });
                            onError(err);
                        });
                }
            });
    },
    form: {
        post: (
            path,
            body,
            callback = () => {},
            message = { success: "success", error: "error" },
            onError = () => {},
            config = {}
        ) => {
            axiosIns
                .post(
                    path,
                    serialize(body, {
                        indices: true,
                        dotsForObjectNotation: true
                    }),
                    config
                )
                .then(data => {
                    if (data.status == 200 && message !== false) {
                        toast.open({
                            message: message.success,
                            type: "success",
                            duration: 2000,
                            dismissible: true
                        });
                    }
                    callback(data);
                })
                .catch(err => {
                    onError(err);
                    toast.open({
                        message: err.response.data,
                        type: "error",
                        duration: 2000,
                        dismissible: true
                    });
                });
        },
        put: (
            path,
            body,
            callback = () => {},
            message = { success: "success", error: "error" },
            onError = () => {},
            config = {}
        ) => {
            axiosIns
                .put(
                    path,
                    serialize(body, {
                        indices: true,
                        dotsForObjectNotation: true
                    }),
                    config
                )
                .then(data => {
                    if (data.status == 200 && message != false) {
                        toast.open({
                            message: message.success,
                            type: "success",
                            duration: 2000,
                            dismissible: true
                        });
                    }
                    callback(data);
                })
                .catch(err => {
                    onError(err);
                    console.log(err.message);
                    toast.open({
                        message: err.response.data,
                        type: "error",
                        duration: 2000,
                        dismissible: true
                    });
                });
        }
    }
};
