import api from "@api";
export default {
    state: {
        lectureList: [],
        lectureDto: {
            name: "",
            year: 0,
            file: null,
            type: 0,
            subjectId: "",
            tagIds: [],
            categories: [],
            doctors: ""
        }
    },
    mutations: {
        Lecture_List(state, payload) {
            state.lectureList = payload;
        },
        Reset_Lecture_Dto(state) {
            Object.assign(state.lectureDto, {
                name: "",
                year: 0,
                file: null,
                type: 0,
                subjectId: "",
                tagIds: [],
                doctors: [],
                categories: []
            });
        },
        Add_Lecture(state, payload) {
            state.lectureList.unshift(payload);
        },
        Delete_Lecture(state, id) {
            state.lectureList.splice(state.lectureList.findIndex(l => l.id == id), 1)
        },
        delete_Lecture_List(state, payload) {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
                MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.lectureList.length; idx++) {
                if (MapOfIds.has(state.lectureList[idx].id) === false) {
                    tempList.push(state.lectureList[idx]);
                }
            }
            state.lectureList = tempList;
        }
    },
    actions: {
        getLectureList({ commit }) {
            api.get("Media/GetAll", ({ data }) => {
                commit("Lecture_List", data);
            });
        },
        addLecture({ commit }, payload) {
            api.form.post(
                "Media/Add", payload,
                ({ data }) => {
                    commit("Add_Lecture", data);
                },
                {
                    success: "تم إضافة المحاضرة بنجاح",
                    error: "فشل إضافة المحاضرة"
                }
            );
        },
        deleteLecture({commit}, id) {
            api.delete(
                "Media/Delete/" + id,
                ({ data }) => {
                    if (data.isSuccess) {
                        commit('Delete_Lecture', id)
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف المحاضرة",
                    success: "تم حذف المحاضرة بنجاح",
                    error: "فشل حذف المحاضرة"
                }
            );
        },
        deleteLectureList({ commit }, ids) {
            api.delete(
                "Media/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("delete_Lecture_List", ids);
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف المحاضرات المحددة",
                    success: "تم حذف المحاضرات المحددة بنجاح",
                    error: "فشل حذف المحاضرات المحددة "
                },
                ids
            );
        }
    }
};
